<script lang="ts" setup>
import Logo from '@/Components/Logo'
import Button from '@/Components/UI/Button.vue'
import GamePad from '@/Components/Icon/GamePad.vue'
import Compass from '@/Components/Icon/Compass.vue'
import CategoryIconMap from '@/Configs/CategoryIconMap.ts'
import GamePadFilled from '@/Components/Icon/GamePadFilled.vue'
import CompassFilled from '@/Components/Icon/CompassFilled.vue'
import SearchPalette from '@/Components/Global/SearchPalette.vue'
import UserDropdown from '@/Components/Navigation/UserDropdown.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import NavigationMobileNav from '@/Components/Navigation/NavigationMobileNav.vue'
import NavigationNavBarItem from '@/Components/Navigation/NavigationNavBarItem.vue'
import NotificationsDropdown from '@/Components/Navigation/NotificationsDropdown.vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import type { NavItem } from '@/Types/NavItem'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Category } from '@/Types/Models/Category'
import { usePageProps } from '@/Composables/usePageProps'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import {
    UserCircleIcon
} from '@heroicons/vue/24/solid'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'
import { HomeIcon as SolidHomeIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
import {
    FireIcon, ArrowUpRightIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, XMarkIcon, HomeIcon, UserGroupIcon
} from '@heroicons/vue/24/outline'

const navOpen = ref<boolean>(false)

const pageProps = usePageProps()

const breakpoints = useBreakpoints(breakpointsTailwind)
const mobileOnly = breakpoints.smallerOrEqual('md')

const categories = pageProps.app?.categories
    ? pageProps.app.categories.map((category: Category) => {
        return {
            title: category.title,
            url: useRoute('articles.categorised', category),
            icon: CategoryIconMap[category.title],
            colour: category.metadata.colour,
            description: category.metadata?.small_description ?? category.metadata?.description
        }
    })
    : []

function primaryNav (solidIcon = false, includeMobile = false): NavItem[] {
    const items: NavItem[] = [
        {
            title: 'Home',
            url: useRoute('home'),
            icon: solidIcon ? SolidHomeIcon : HomeIcon,
            activeIcon: SolidHomeIcon
        },
        {
            title: 'Browse',
            url: null,
            icon: solidIcon ? CompassFilled : Compass,
            activeIcon: CompassFilled,
            children: [
                {
                    title: 'Latest Articles',
                    url: useRoute('articles.index'),
                    icon: FireIcon,
                    description: 'The most recent published articles'
                },
                {
                    title: 'Following',
                    url: useRoute('articles.following'),
                    icon: UserGroupIcon,
                    if: useIsAuthed(),
                    colour: 'var(--color-primary)',
                    description: 'Articles from your favourite writers, tags, and games'
                },
                ...categories
            ]
        },
        // {
        //     title: 'Trending',
        //     url: useRoute('articles.trending'),
        //     icon: ArrowTrendingUpIcon,
        //     if: pageProps.totalTrendingArticleCount > 12
        // },
        {
            title: 'Games',
            url: useRoute('games.index'),
            icon: solidIcon ? GamePadFilled : GamePad,
            activeIcon: GamePadFilled
        }
    ]

    if (includeMobile) {
        items.push({
            title: 'Search',
            url: useRoute('search.index'),
            icon: MagnifyingGlassIcon
        })

        items.push({
            title: 'About us',
            url: useRoute('about-us'),
            icon: InformationCircleIcon
        })
    }

    return items
}

function canShowItem (item: NavItem): boolean {
    let canShow = (item.if !== undefined ? item.if : true)

    if (item.permission !== undefined && canShow) {
        canShow = item.permission === true
    }

    return canShow
}

const searchOpen = ref<boolean>(false)

function closeSearch () {
    searchOpen.value = false
}

function openSearch () {
    searchOpen.value = true

    useAnalyticsEvent('Open search palette')
}
</script>

<template>
    <div class="sticky top-0 z-50">
        <nav
            id="siteNav"
            class="relative z-50 flex h-16 w-full border-b border-black/10 text-base transition-all bg-primary dark:bg-zinc-950 dark:text-white md:justify-center md:border-zinc-200 md:bg-white dark:md:border-white/10">
            <NavigationMobileNav
                v-if="mobileOnly"
                :menu="primaryNav(true, true)"
                :open="navOpen"
                @close="navOpen = false" />

            <button
                v-if="mobileOnly"
                :class="navOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'"
                class="absolute left-0 top-16 z-0 h-[calc(100dvh-4rem)] w-full bg-black/50 transition-all duration-500"
                @click="navOpen = false">
                <span class="sr-only">Close nav</span>
            </button>
            <div class="mx-auto flex w-full flex-wrap items-center px-5 max-w-[2000px] md:justify-between">
                <div class="mr-2 flex w-8 md:hidden">
                    <button
                        class="rounded-full text-white ring-0 ring-primary/30 xxs:-mt-0.5 focus:text-primary focus:text-white focus:ring-2 dark:text-white/75 md:focus-primary md:text-zinc-900/75"
                        @click="navOpen = !navOpen">
                        <span class="sr-only">Toggle mobile nav</span>
                        <Bars3BottomLeftIcon
                            v-if="!navOpen"
                            class="w-6" />
                        <XMarkIcon
                            v-else
                            class="w-6" />
                    </button>
                </div>
                <div
                    class="flex h-full w-2/12 items-center xl:w-7/12">
                    <Link
                        :href="useRoute('home')"
                        prefetch>
                        <span class="sr-only">Link to home</span>
                        <Logo.FullColour
                            class="hidden w-32 pl-4 transition-all hover:scale-105 md:flex lg:w-44 dark:md:hidden" />
                        <Logo.FullWhite
                            class="hidden w-32 pl-4 transition-all hover:scale-105 md:hidden lg:w-44 dark:md:flex" />
                        <Logo.FullWhite class="flex h-8 md:hidden" />
                    </Link>
                    <div class="ml-6 hidden h-full items-center justify-center md:flex">
                        <ul class="flex h-full flex-row items-center gap-0.5">
                            <template v-for="(item, i) in primaryNav()">
                                <li
                                    v-if="(typeof item.permission === 'undefined' || item.permission === true) && canShowItem(item)"
                                    :key="i"
                                    class="h-full"
                                    :class="item.classes">
                                    <NavigationNavBarItem :item="item" />
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <ul class="ml-auto flex h-full items-center justify-end gap-2.5 md:w-6/12 xl:w-5/12">
                    <li
                        v-if="useIsAuthed()"
                        class="hidden md:flex">
                        <ArticleCreateButton
                            text="New article" />
                    </li>
                    <li class="relative flex">
                        <span
                            class="absolute top-1/2 z-10 hidden -translate-y-1/2 rounded-sm bg-zinc-100 px-2 py-1 text-xs font-semibold text-zinc-700 right-2.5 dark:bg-zinc-900 dark:text-zinc-200 lg:flex">
                            <span>/</span>
                        </span>
                        <Button
                            class="hidden lg:flex"
                            variant="white"
                            :icon="MagnifyingGlassIcon"
                            icon-first
                            @click="openSearch">
                            <span class="lg:pr-12">
                                Search
                            </span>
                        </Button>
                        <button
                            class="ml-2 text-white md:text-zinc-500 lg:hidden"
                            @click="openSearch">
                            <MagnifyingGlassIcon class="w-5" />
                        </button>
                    </li>
                    <li
                        v-if="useIsAuthed()"
                        class="flex h-full">
                        <NotificationsDropdown />
                    </li>
                    <li
                        v-if="!useIsAuthed() && mobileOnly"
                        class="flex h-full items-center">
                        <AuthModalTrigger>
                            <template #trigger>
                                <button class="flex items-center text-white">
                                    <UserCircleIcon class="w-7" />
                                </button>
                            </template>
                        </AuthModalTrigger>
                    </li>

                    <li v-if="!useIsAuthed() && !mobileOnly">
                        <AuthModalTrigger>
                            <template #trigger>
                                <Button
                                    class="flex"
                                    variant="white"
                                    :icon="ArrowUpRightIcon">
                                    Log in
                                </Button>
                            </template>
                        </AuthModalTrigger>
                    </li>
                    <li v-if="!useIsAuthed() && !mobileOnly">
                        <AuthModalTrigger default-form="register">
                            <template #trigger>
                                <Button
                                    class="flex"
                                    :icon="UserCircleIcon">
                                    Sign up
                                </Button>
                            </template>
                        </AuthModalTrigger>
                    </li>
                    <li v-if="useIsAuthed()">
                        <UserDropdown />
                    </li>
                </ul>
            </div>
        </nav>
        <SearchPalette
            :show="searchOpen"
            @open="openSearch"
            @close="closeSearch" />
    </div>
</template>
