<script setup>

import ErrorText from '@/Components/UI/ErrorText.vue'
import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: {
        type: [String, null],
        default: null
    },
    options: { type: Array },
    value: { type: [Boolean, Object] },
    error: {
        type: [String, null],
        default: null
    },
    spacing: {
        type: String,
        default: 'gap-4'
    },
    label: {
        type: String,
        default: null
    }
})

const name = Math.random().toString(36).slice(2)

const proxyChecked = computed({
    get () {
        return props.modelValue
    },

    set (value) {
        emit('update:modelValue', value)
    }
})
</script>

<template>
    <div
        class="flex flex-col"
        :class="spacing">
        <label
            v-for="option in options"
            :key="option.value"
            class="flex text-left md:items-center">
            <input
                v-model="proxyChecked"
                :name="name"
                :value="option.value"
                class="h-4 w-4 rounded-full border-zinc-300 bg-white text-white checked:border-primary checked:border-4"
                type="radio">
            <span
                v-if="option.label"
                class="mt-px ml-2 select-none text-sm text-zinc-900/75">{{ option.label }}</span>
        </label>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
