import { ref } from 'vue'
import type { Post } from '@/Types/Models/Post'
import { usePermissions } from '@/Composables/usePermissions'
import { UserPermission } from '@/Enums/UserPermission.ts'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser'
import { usePost } from '@/Composables/usePost'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { NotificationType } from '@/Enums/NotificationType.ts'
import useEmitter from '@/Composables/useEmitter'

const deletingPost = ref<Post | null>(null)
const showDeleteConfirmation = ref(false)

export function useHandlePostDeleting () {
    function confirmDelete (post: Post) {
        deletingPost.value = post
        showDeleteConfirmation.value = true
    }

    function closeDeleteConfirmation () {
        deletingPost.value = null
        showDeleteConfirmation.value = false
    }

    function deletePost (post: Post) {
        usePost({
            url: useRoute('posts.destroy', { post }),
            method: 'DELETE',
            onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
            onSuccess: response => {
                useEmitter.emit('post:deleted', post)
                showDeleteConfirmation.value = false
            }
        })
    }

    return {
        deletePost,
        deletingPost,
        showDeleteConfirmation,
        closeDeleteConfirmation,
        confirmDelete
    }
}
