import { ref } from 'vue'
import type { Comment } from '@/Types/Models/Comment'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { NotificationType } from '@/Enums/NotificationType.ts'
import useEmitter from '@/Composables/useEmitter'
import { usePost } from '@/Composables/usePost'
import { useForm } from '@inertiajs/vue3'

const deletingComment = ref<Comment | null>(null)
const reportingComment = ref<Comment | null>(null)
const showCommentDeleteConfirmation = ref(false)
const showCommentReportConfirmation = ref(false)
const commentReported = ref(false)
const reportingCommentForm = useForm({
    reason: null,
    reason_text: null
})

export function useHandleComment () {
    const reportReasons = [
        {
            label: 'Spam',
            value: 'spam'
        },
        {
            label: 'Abusive content',
            value: 'abuse'
        },
        {
            label: 'Pornographic content',
            value: 'porn'
        },
        {
            label: 'Other',
            value: 'other'
        }
    ]

    function confirmDeleteComment (comment: Comment) {
        deletingComment.value = comment
        showCommentDeleteConfirmation.value = true
    }
    function confirmReportComment (comment: Comment) {
        reportingComment.value = comment
        showCommentReportConfirmation.value = true
    }

    function closeCommentDeleteConfirmation () {
        deletingComment.value = null
        showCommentDeleteConfirmation.value = false
    }
    function closeCommentReportConfirmation () {
        reportingComment.value = null
        showCommentReportConfirmation.value = false
    }

    function submitReport () {
        reportingCommentForm.post(useRoute('comment.report', reportingComment.value), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: response => {
                commentReported.value = true
                useEmitter.emit('comment:reported', reportingComment.value)
                reportingCommentForm.reset()
            }
        })
    }

    function deleteComment (comment: Comment) {
        usePost({
            url: useRoute('comment.destroy', { comment }),
            method: 'DELETE',
            onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
            onSuccess: response => {
                useEmitter.emit('comment:deleted', comment)
                showCommentDeleteConfirmation.value = false
            }
        })
    }

    return {
        deleteComment,
        confirmReportComment,
        reportingComment,
        closeCommentReportConfirmation,
        showCommentReportConfirmation,
        deletingComment,
        submitReport,
        reportingCommentForm,
        commentReported,
        reportReasons,
        showCommentDeleteConfirmation,
        closeCommentDeleteConfirmation,
        confirmDeleteComment
    }
}
