<script setup>
import Spinner from '@/Components/UI/Spinner.vue'
import SearchImage from '~/app/decoration/search.svg'
import FadeUp from '@/Components/Transition/FadeUp.vue'
import ModalBackdrop from '@/Components/UI/ModalBackdrop.vue'
import ModalContainer from '@/Components/UI/ModalContainer.vue'
import { Link } from '@inertiajs/vue3'
import { useDebounceFn } from '@vueuse/core'
import { useRoute } from '@/Composables/useRoute.ts'
import { ref, computed, nextTick, watch } from 'vue'
import {
    MagnifyingGlassIcon,
    ChevronLeftIcon
} from '@heroicons/vue/20/solid'

const props = defineProps({
    searchRoute: {
        type: String,
        required: true
    },
    linkToSearch: {
        type: Boolean,
        default: true
    },
    show: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['close', 'open', 'selected'])

const resultInputRef = ref(null)
const resultInputRefContainer = ref(null)
const resultInput = defineModel('input', { type: String, default: '' })
const results = ref([])
const selectedResultIndex = defineModel('selectedIndex', { type: Number, default: -1 })
const loading = ref(false)
const error = ref(null)

const fetchResults = async (query) => {
    loading.value = true
    error.value = null
    try {
        const response = await fetch(useRoute(props.searchRoute, { query }))
        if (!response.ok) {
            throw new Error('Something went wrong')
        }
        results.value = await response.json()
        selectedResultIndex.value = -1
    } catch (err) {
        error.value = err.message
    } finally {
        loading.value = false
    }
}

const debouncedFetchResults = useDebounceFn(fetchResults, 300)

watch(resultInput, (value) => {
    if (value.trim()) {
        debouncedFetchResults(value)
    } else {
        results.value = []
    }
})

// eslint-disable-next-line sonarjs/cognitive-complexity
const navigate = (action) => {
    if (action === 'up') {
        selectedResultIndex.value = selectedResultIndex.value > 0 ? selectedResultIndex.value - 1 : results.value.length - 1
    } else if (action === 'down') {
        selectedResultIndex.value = selectedResultIndex.value < results.value.length - 1 ? selectedResultIndex.value + 1 : 0
    } else if (action === 'enter') {
        selectResult(selectedResultIndex.value)
    }

    nextTick(() => {
        const resultList = resultInputRefContainer.value.nextElementSibling
        const selectedElement = resultList.children[selectedResultIndex.value]

        if (selectedElement) {
            const listRect = resultList.getBoundingClientRect()
            const selectedRect = selectedElement.getBoundingClientRect()

            if (selectedRect.top < listRect.top) {
                resultList.scrollTop -= listRect.top - selectedRect.top
            } else if (selectedRect.bottom > listRect.bottom) {
                resultList.scrollTop += selectedRect.bottom - listRect.bottom
            }
        }
    })
}

const selectResult = (index) => {
    selectedResultIndex.value = index
    emit('selected', results.value[index])
    // close()
}

const filteredResults = computed(() => results.value)

watch(() => props.show, () => {
    if (props.show) {
        document.body.style.overflow = 'hidden'
        setTimeout(() => {
            resultInputRef.value.focus()
        }, 100)
    } else {
        document.body.style.overflow = null
    }
})

const close = () => {
    if (props.show) {
        emit('close')
        setTimeout(() => {
            resultInput.value = ''
            results.value = []
            selectedResultIndex.value = -1
            loading.value = false
            error.value = null
        }, 200)
    }
}
</script>

<template>
    <teleport to="body">
        <Transition leave-active-class="duration-200">
            <div
                v-show="show"
                class="fixed inset-0 z-50 overflow-y-auto px-0 sm:py-6 md:py-24"
                scroll-region>
                <ModalBackdrop
                    :show="show"
                    @click="close" />

                <FadeUp>
                    <ModalContainer
                        v-if="show"
                        class="max-w-3xl min-h-[75dvh] max-h-[75dvh] sm:min-h-0">
                        <div class="relative z-50">
                            <div
                                ref="resultInputRefContainer"
                                class="relative group">
                                <input
                                    ref="resultInputRef"
                                    :value="resultInput"
                                    type="text"
                                    class="w-full border-b border-zinc-200 p-2 px-3 py-3 pr-32 pl-12 transition-colors focus:outline-hidden dark:border-zinc-700 dark:bg-zinc-900 sm:text-sm"
                                    placeholder="Search..."
                                    @input="resultInput = $event.target.value"
                                    @keydown.esc.prevent="close"
                                    @keydown.up.prevent="navigate('up')"
                                    @keydown.down.prevent="navigate('down')"
                                    @keydown.enter.prevent="navigate('enter')"
                                >
                                <div
                                    class="absolute top-1/2 left-4 z-50 flex -translate-y-1/2 items-center justify-center text-zinc-900/30 group-focus-within:text-zinc-900/50 transition-colors dark:group-focus-within:text-white dark:text-white/75">
                                    <MagnifyingGlassIcon class="hidden w-5 md:flex" />
                                    <button
                                        class="md:hidden"
                                        @click="close">
                                        <ChevronLeftIcon class="w-5" />
                                    </button>
                                </div>
                                <Link
                                    v-if="resultInput.length > 0 && linkToSearch"
                                    :key="resultInput"
                                    :href="useRoute('search.index', {query: resultInput})"
                                    class="absolute top-1/2 right-4 z-50 -translate-y-1/2 text-xs text-zinc-900/75 hover:text-primary dark:text-white/75">
                                    See all results
                                </Link>
                            </div>
                            <div
                                v-if="loading"
                                class="justify-center px-4 py-8 text-center">
                                <Spinner class="mx-auto w-6 text-transparent" />
                            </div>
                            <div
                                v-else-if="error"
                                class="p-4 text-center text-sm text-red-500">
                                Error: {{ error }}
                            </div>
                            <div
                                v-else-if="resultInput.length > 0 && filteredResults.length === 0 && !loading"
                                class="break-all p-8 text-center">
                                No results for <em class="ml-1">&ldquo;{{ resultInput }}&rdquo;</em>
                            </div>

                            <div v-else-if="resultInput.length === 0">
                                <slot name="default">
                                    <div class="z-50 flex flex-col items-center gap-2 break-all p-8 text-center text-sm text-zinc-500 dark:text-zinc-400">
                                        <img
                                            alt="Search icon"
                                            :src="SearchImage"
                                            class="w-20">
                                        Search for articles, users, or games
                                    </div>
                                </slot>
                            </div>
                            <div
                                v-else
                                class="max-h-[calc(100dvh-10rem)] overflow-auto py-2">
                                <slot
                                    name="results"
                                    :results="results" />
                            </div>
                        </div>
                    </ModalContainer>
                </FadeUp>
            </div>
        </Transition>
    </teleport>
</template>
