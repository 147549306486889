<script setup lang="ts">
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import PostReportModal from '@/Components/Post/PostReportModal.vue'
import CommentReportModal from '@/Components/Comment/CommentReportModal.vue'
import { useHandleComment } from '@/Composables/useHandleComment.ts'
import { useHandlePostPinning } from '@/Composables/useHandlePostPinning.ts'
import { useHandlePostDeleting } from '@/Composables/useHandlePostDeleting.ts'
import { useHandlePostReporting } from '@/Composables/useHandlePostReporting.ts'

const { showCommentDeleteConfirmation, closeCommentDeleteConfirmation, deletingComment, deleteComment, reportingComment, closeCommentReportConfirmation, showCommentReportConfirmation } = useHandleComment()
const { showReportConfirmation, closeReportConfirmation, reportingPost } = useHandlePostReporting()
const { showDeleteConfirmation, closeDeleteConfirmation, deletingPost, deletePost } = useHandlePostDeleting()
const { showPinConfirmation, closePinConfirmation, pinningPost, pinPost } = useHandlePostPinning()
</script>

<template>
    <div>
        <Modal
            :show="showCommentDeleteConfirmation"
            max-width="md"
            @close="closeCommentDeleteConfirmation">
            <template #title>
                Delete Comment
            </template>

            <template #content>
                Are you sure you want to delete this comment?
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="closeCommentDeleteConfirmation">
                        Cancel
                    </Button>

                    <Button
                        variant="danger"
                        @click="deleteComment(deletingComment)">
                        Delete
                    </Button>
                </div>
            </template>
        </Modal>

        <CommentReportModal
            :comment="reportingComment"
            :show="showCommentReportConfirmation"
            @close="closeCommentReportConfirmation" />

        <PostReportModal
            :post="reportingPost"
            :show="showReportConfirmation"
            @close="closeReportConfirmation" />

        <Modal
            :show="showPinConfirmation"
            max-width="md"
            @close="closePinConfirmation">
            <template #title>
                Pin Post
            </template>

            <template #content>
                Pin this post to the top of the feed? It will remove the current pinned post.
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="closePinConfirmation">
                        Cancel
                    </Button>

                    <Button
                        variant="primary"
                        @click="pinPost(pinningPost)">
                        Confirm
                    </Button>
                </div>
            </template>
        </Modal>

        <Modal
            :show="showDeleteConfirmation"
            max-width="md"
            @close="closeDeleteConfirmation">
            <template #title>
                Delete Post
            </template>

            <template #content>
                Are you sure you want to delete this post?
            </template>

            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <Button
                        variant="white"
                        @click="closeDeleteConfirmation">
                        Cancel
                    </Button>

                    <Button
                        variant="danger"
                        @click="deletePost(deletingPost)">
                        Delete
                    </Button>
                </div>
            </template>
        </Modal>
    </div>
</template>
