import { ref } from 'vue'
import type { Post } from '@/Types/Models/Post'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import useEmitter from '@/Composables/useEmitter'
import { usePost } from '@/Composables/usePost'
import { useToast } from '@/Composables/useToast.ts'
import { NotificationType } from '@/Enums/NotificationType.ts'

const pinningPost = ref<Post | null>(null)
const showPinConfirmation = ref(false)

export function useHandlePostPinning () {
    function pinPost (post: Post) {
        usePost({
            url: useRoute('posts.pin', { post }),
            method: 'POST',
            onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
            onSuccess: response => {
                useEmitter.emit('post:pinned', post)
                showPinConfirmation.value = false
            }
        })
    }

    function unpinPost (post: Post) {
        usePost({
            url: useRoute('posts.unpin', { post }),
            method: 'DELETE',
            onUnauthorized: () => useToast('Unauthorized action', NotificationType.FAIL),
            onSuccess: response => {
                useEmitter.emit('post:unpinned', post)
                showPinConfirmation.value = false
            }
        })
    }

    function confirmPin (post: Post) {
        pinningPost.value = post
        showPinConfirmation.value = true
    }

    function closePinConfirmation () {
        pinningPost.value = null
        showPinConfirmation.value = false
    }

    return {
        pinPost,
        unpinPost,
        pinningPost,
        showPinConfirmation,
        closePinConfirmation,
        confirmPin
    }
}
