import { ref } from 'vue'
import type { Post } from '@/Types/Models/Post'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import useEmitter from '@/Composables/useEmitter'

const reportingPost = ref<Post | null>(null)
const showReportConfirmation = ref(false)
const reported = ref(false)
const reportForm = useForm({
    reason: null,
    reason_text: null
})

export function useHandlePostReporting () {
    const reportReasons = [
        {
            label: 'Spam',
            value: 'spam'
        },
        {
            label: 'Abusive content',
            value: 'abuse'
        },
        {
            label: 'Pornographic content',
            value: 'porn'
        },
        {
            label: 'Other',
            value: 'other'
        }
    ]

    function submitReport () {
        reportForm.post(useRoute('posts.report', reportingPost.value), {
            preserveState: true,
            preserveScroll: true,
            onSuccess: response => {
                reported.value = true
                useEmitter.emit('post:reported', reportingPost.value)
                reportForm.reset()
            }
        })
    }

    function confirmReport (post: Post) {
        reportingPost.value = post
        showReportConfirmation.value = true
    }

    function closeReportConfirmation () {
        reportingPost.value = null
        showReportConfirmation.value = false
    }

    return {
        reported,
        reportReasons,
        reportForm,
        submitReport,
        reportingPost,
        showReportConfirmation,
        closeReportConfirmation,
        confirmReport
    }
}
