<script setup lang="ts">
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import InputText from '@/Components/Input/InputText.vue'
import InputRadio from '@/Components/Input/InputRadio.vue'
import type { Post } from '@/Types/Models/Post'
import { onMounted, type PropType, ref } from 'vue'
import { useHandlePostReporting } from '@/Composables/useHandlePostReporting.ts'

const props = defineProps({
    post: Object as PropType<Post>,
    show: {
        type: Boolean,
        default: false
    }
})

const open = ref(false)
const { reportForm, reported, submitReport, reportReasons } = useHandlePostReporting()

function close () {
    open.value = false
    emit('close')
    setTimeout(() => {
        reported.value = false
    }, 200)
}

onMounted(() => {
    if (props.show) {
        open.value = true
    }
})

const emit = defineEmits(['reported', 'close'])

useEmitter.on('post:reported', (post: Post) => {
    if (post.id !== props.post.id) {
        return
    }

    reported.value = true
    emit('reported')
})
</script>

<template>
    <Modal
        :show="open || show"
        max-width="lg"
        @close="close">
        <template #title>
            Report Post
        </template>

        <template #content>
            <div class="my-2">
                <div v-if="!reported">
                    <InputRadio
                        v-model="reportForm.reason"
                        :error="reportForm.errors.reason"
                        :options="reportReasons" />
                    <InputText
                        v-if="reportForm.reason === 'other'"
                        v-model="reportForm.reason_text"
                        :error="reportForm.errors.reason_text"
                        class="mt-4"
                        label="Reason" />
                </div>
                <div v-if="reported">
                    Post has been reported, we'll let you know the outcome
                </div>
            </div>
        </template>

        <template #footer>
            <div
                :class="reported ? 'justify-end' : 'justify-between'"
                class="flex w-full flex-col-reverse items-center xs:flex-row">
                <Button
                    v-if="!reported"
                    variant="white"
                    @click="close">
                    Cancel
                </Button>

                <Button
                    v-if="!reported"
                    variant="danger"
                    @click="submitReport">
                    Report
                </Button>
                <Button
                    v-if="reported"
                    variant="primary"
                    @click="close">
                    Okay
                </Button>
            </div>
        </template>
    </Modal>
</template>
