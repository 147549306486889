<script setup lang="ts">
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { cn } from '@/utils.ts'
import { Link, router } from '@inertiajs/vue3'
import type { NavItem } from '@/Types/NavItem'
import { onMounted, type PropType, ref } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

const emit = defineEmits(['clicked'])

const props = defineProps({
    item: Object as PropType<NavItem>
})

const open = ref(false)
const active = ref(false)

function markActive () {
    active.value = useCurrentUrl() === props.item.url ||
        (useCurrentUrl() + '/') === props.item.url ||
        useCurrentUrl() === props.item.url + '/'

    if (props.item.children?.length) {
        props.item.children.forEach((child) => {
            if (useCurrentUrl() === child.url) {
                active.value = true
                open.value = true
            }
        })
    }
}

function childActive (child: NavItem): boolean {
    return useCurrentUrl() === child.url
}

function handleClick () {
    emit('clicked')
}

onMounted(() => {
    router.on('navigate', () => {
        markActive()
    })
    markActive()
})

function show (item: NavItem): boolean {
    let canShow = (item.if !== undefined ? item.if : true)

    if (item.permission !== undefined && canShow) {
        canShow = item.permission === true
    }

    return canShow
}
</script>

<template>
    <li
        v-if="(typeof item.permission === 'undefined' || item.permission === true) && show(item)"
        :class="[
            cn(
                'text-sm  dark:text-white rounded-lg p-2.5 px-2.5 border border-transparent',
                active ? 'bg-white/10 dark:bg-white/10 text-white' : 'bg-transparent text-white',
                open && !active ? 'bg-white/5 dark:bg-white/5 text-white' : '',
                item.classes,
            )
        ]">
        <component
            :is="item.url ? Link : 'button'"
            :href="item.url"
            class="flex w-full flex-col"
            @click="item.url ? handleClick() : open = !open">
            <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                    <component
                        :is="item.icon"
                        v-if="item.icon && ['object', 'function'].includes(typeof item.icon)"
                        class="mr-2 w-5" />
                    {{ item.title }}
                </div>
                <ChevronDownIcon
                    v-if="item.children"
                    :class="open ? 'rotate-180' : 'rotate-0'"
                    class="w-5 transition-all" />
            </div>
        </component>
        <AccordionTransition>
            <div
                v-if="open && item.children"
                class="mt-4 flex flex-col rounded-b-xl pb-2 -mx-2.5 gap-1.5">
                <template v-for="(child, i) in item.children">
                    <li
                        v-if="(typeof child.permission === 'undefined' || child.permission === true) && show(child)"
                        :key="i"
                        class="py-2 pl-6 pr-2.5"
                        :class="childActive(child) ? 'bg-white/20' : ''"
                        :style="{'--i': i}">
                        <Link
                            :as="['delete', 'post', 'path'].includes(child.method) ? 'button' : 'a'"
                            :href="child.url"
                            :method="child.method"
                            :class="child.url === useCurrentUrl() ? 'font-bold text-white' : 'text-white'"
                            class="flex w-full items-center gap-4 text-sm"
                            @click="handleClick">
                            <div
                                class="flex shrink-0 group-hover/navitem:scale-105 items-center justify-center rounded-lg bg-white transition-all duration-300 size-10 bg-primary">
                                <component
                                    :is="child.icon"
                                    v-if="child.icon && ['object', 'function'].includes(typeof child.icon)"
                                    :style="{color: child?.colour ?? 'var(--color-primary)'}"
                                    class="flex w-6" />
                            </div>
                            <div class="flex flex-col">
                                <div class="flex flex-col items-start justify-start text-left">
                                    <p class="text-sm font-semibold">
                                        {{ child.title }}
                                    </p>
                                    <p
                                        v-if="child.description"
                                        class="-mt-1 text-xs text-white/75 text-pretty line-clamp-1">
                                        {{ child.description }}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </li>
                </template>
            </div>
        </AccordionTransition>
    </li>
</template>
